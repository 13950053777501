.root {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

header {
  height: 96px;
  width: 100%;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content > div {
  margin-top: 2rem;
}
.welcome {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1b483b;
  font-size: 74px;
  font-weight: 700;
}