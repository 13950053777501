.footer {
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  color: #939393;
  font-size: 1rem;
}
.footer a {
  text-decoration: none;
  color: #939393;
  font-size: 1rem;
  margin-left: 8px;
}